<template>
  <div class="layout-header-section d-lg-flex d-block justify-content-between">
    <div class="header-label col-lg-4 col-12">Polls Reports</div>
    <div class="
            col-lg-8 col-12
            text-end
            d-flex
            justify-content-end
            custom-flex-cloumn-mob
          ">
    </div>
  </div>
  <div class="company-section-outer" style="height: calc(100vh - 125px);">
    <div class="row g-3">
      <div class="col-lg-5 col-md-5 col-12">
        <div class="feedback-chart-outer">
          <div class="d-flex justify-content-between">
            <div class="chart-label">Citizen Click Report</div>
            <div class=""><button type="button" title="Refresh" class="btn custom-outline-view-btn mt-2 me-3"
                @click="campaignreport({ ah1: this.routeParam })">
                <i class="pi pi-refresh call-btn-color"></i>
              </button></div>
          </div>
          <div>
            <Chart type="doughnut" :data="chartDataCampaign" :options="campaignOptions" responsive="true" />
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-12">
        <div class="card border-success shadow p-3 mb-5 bg-white rounded">
          <div class="card-body">
            <ul class="list-group list-group-flush">
              <li class="list-group-item" v-for="msg  in chartDataValue" :key="msg">
                <div class="d-flex justify-content-between"><span>{{ msg.label }}</span><span class="fw-bold">{{ msg.count
                }}</span></div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ApiService from "../../service/ApiService";
import { useRoute } from "vue-router";
export default {
  data() {
    return {
      chartDataCampaign: {
        labels: ["Yes", "No", "Maybe"],
        datasets: [
          {
            data: [3, 1, 2],
            backgroundColor: [
              "#FF9FB2",
              "#FEDA87",
              "#80D39B",
              "#7C90DB",
              "#263C75",
            ],
            hoverBackgroundColor: [
              "#FF9FB2",
              "#FEDA87",
              "#80D39B",
              "#7C90DB",
              "#263C75",
            ],
          },
        ],
      },
      campaignOptions: {
        plugins: {
          legend: {
            labels: {
              color: "#495057",
            },
          },
        },
      },
      chartDataValue: [
        {
          label: "Yes",
          count: 3,
        },
        {
          label: "No",
          count: 1,
        },
        {
          label: "Maybe",
          count: 2,
        },
      ],
      ApiLoadingSwitch: false,
    };
  },
  ApiService: null,
  created() {
    this.ApiService = new ApiService();
  },

  mounted() {
    this.loading = true;
    const route = useRoute();
    this.routeParam = route.params.campaignId;
    this.campaignreport({ ah1: this.routeParam });
  },
  beforeUnmount() {
    clearInterval(this.timer4);
  },
  methods: {
    campaignreport(item) {
      this.ApiService.campaignreport(item).then((data) => {
        if (data.success == true) {
          this.chartDataCampaign = {
            labels: data.labels,
            datasets: [
              {
                data: data.data,
                backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
                hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
              },
            ],
          };
          this.chartDataValue = data.datatable;
        }
      });
    },
    switchLoadingStatus() {
      if (this.ApiLoadingSwitch == true) {
        this.timer4 = window.setInterval(() => {
          this.campaignreport({ ah1: this.routeParam });
        }, 10000);
      } else {
        clearInterval(this.timer4);
      }
    },
  },
};
</script>
<style scoped>
.feedback-chart-outer {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e0e5ed;
  border-radius: 4px;
}

.feedback-chart-outer .chart-label {
  font-family: "AcuminPro-SemiBold";
  font-size: 16px;
  letter-spacing: 0.32px;
  color: #4a5463;
  text-align: left;
  line-height: 16px;
  padding: 16px;
}
</style>